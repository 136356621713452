import _objectSpread from "/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import service from '@/utils/request'; // @Tags EquitySpecs
// @Summary 创建EquitySpecs
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquitySpecs true "创建EquitySpecs"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /resource/spec [post]

export var createEquitySpecs = function createEquitySpecs(data) {
  return service({
    url: "/resource/spec",
    method: 'post',
    data: data
  });
}; // @Tags EquitySpecs
// @Summary 更新EquitySpecs
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquitySpecs true "更新EquitySpecs"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /resource/spec [post]

export var updateEquitySpecs = function updateEquitySpecs(data) {
  return service({
    url: "/resource/spec",
    method: 'put',
    data: data
  });
}; // @Tags EquitySpecs
// @Summary 分页获取EquitySpecs列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取EquitySpecs列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /resource/spec [get]

export var getEquitySpecsList = function getEquitySpecsList(params) {
  params = _objectSpread({
    goods_id: -1
  }, params);
  return service({
    url: "/resource/spec",
    method: 'get',
    params: params
  });
}; // @Tags EquitySpecs
// @Summary 修改EquitySpecs状态
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquitySpecs true "更新EquitySpecs"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /resource/spec/status [put]

export var changeEquitySpecs = function changeEquitySpecs(data) {
  return service({
    url: "/resource/spec/status",
    method: 'post',
    data: data
  });
};